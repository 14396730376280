import { tv } from 'tailwind-variants'

export const buttonVariants = tv({
  base: 'text-md inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-full font-semibold transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
  variants: {
    variant: {
      primary: 'bg-primary-p1 text-button-on-primary',
      'primary-p2': 'bg-primary-p2 text-primary-p1 hover:bg-primary-p2-hover',
      secondary: 'bg-secondary-p2 hover:bg-secondary-p2 text-primary-p1',
      third: 'bg-third-p1 text-third-on-third',
      gray: 'bg-background-2nd text-label-l2',
      'gray-gray2': 'bg-gray-gray2 text-gray-gray5',
      'gray-outline': 'border-l-l3 bg-background-2nd text-l-l1 border',
      base: 'border-label-l3 bg-background-1st hover:bg-background-1st border border-solid',
      'glass-options': 'bg-glass-special-options text-playseeGray-gray1',
      text: 'text-primary-p1',
      'label-l1': 'text-label-l1',
      'label-l2': 'text-label-l2 text-body',
      'light-background':
        'border-label-l3 bg-background-2nd hover:bg-background-2nd border border-solid',
      'background-b1': 'bg-background-b1 text-label-l1',
      'background-overlay': 'bg-overlay text-palup-white',
      outline: 'border-label-l3 text-label-l1 border',
      'button-p1': 'bg-button-p1 text-button-on-primary',
    },
    size: {
      default: 'h-10 px-4 py-2',
      none: '',
      sm: 'h-8 px-3',
      lg: 'h-11 px-8',
      icon: 'h-10 w-10',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'default',
  },
})
