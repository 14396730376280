'use client'

import { cn } from '@paladise/ui/lib/utils'
import { Slot, Slottable } from '@radix-ui/react-slot'
import * as React from 'react'
import { type VariantProps } from 'tailwind-variants'
import { buttonVariants } from './button.style'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  loadingIcon?: React.ReactNode
  loadingClassName?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      loading,
      loadingIcon,
      loadingClassName,
      disabled,
      onClick,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        disabled={disabled}
        onClick={e => {
          if (disabled || loading) return
          onClick?.(e)
        }}
      >
        <Slottable>{children}</Slottable>
        {loading
          ? (loadingIcon ?? (
              <span
                className={cn(
                  'i-ps-cell_loading text-label-still-l1 ml-1 animate-spin',
                  loadingClassName,
                )}
              />
            ))
          : null}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button }
