export const CDN_URL = 'https://storage.googleapis.com/ai-web-assets'

export const ONELINK_STAGE = 'https://palupstage.onelink.me/4Ah8/8xvmz6vs'
export const ONELINK_BETA = 'https://palupbeta.onelink.me/RrMs/fexxobfj'
export const ONELINK_PROD = 'https://palup.onelink.me/wrme/5uh4k2o4'

export const ONELINK_URL = (env: string) => {
  switch (env) {
    case 'beta':
      return ONELINK_BETA
    case 'prod':
      return ONELINK_PROD
    case 'stage':
    default:
      return ONELINK_STAGE
  }
}

export const NO_PHOTO_PATH = CDN_URL + '/human/images/no_photo.svg' // if path is public can use /image...

export const BINDING_GOOGLE_ACCOUNT_URL = '/api/auth/bind/google'

export const AUTHORIZATION_GOOGLE_ACCOUNT_URL = '/api/auth/callback/google'

export const AUTHORIZATION_DROPBOX_ACCOUNT_URL = '/api/auth/callback/dropbox'

export const AUTHORIZATION_ONE_DRIVE_ACCOUNT_URL =
  '/api/auth/callback/one-drive'

export const BINDING_TIKTOK_ACCOUNT_URL = '/api/auth/bind/tik-tok'

export const AUTHORIZATION_TIKTOK_ACCOUNT_URL = '/api/auth/callback/tik-tok'

export const BINDING_INSTAGRAM_ACCOUNT_URL = '/api/auth/bind/instagram'

export const AUTHORIZATION_INSTAGRAM_ACCOUNT_URL =
  '/api/auth/callback/instagram'

// --- Other config ---
export const CONSENT_COOKIE_NAME = 'pca'
export const USER_COOKIE_NAME = '__playsee_user'

export const AUTH_COOKIE_NAME = 'session'

export const REFRESH_COOKIE_NAME = 'refresh'

export const USER_INFO_COOKIE_NAME = 'user'

export const GOOGLE_TOKEN_COOKIE_NAME = 'google_session'

export const DROPBOX_TOKEN_COOKIE_NAME = 'dropbox_session'

export const ONE_DRIVE_TOKEN_COOKIE_NAME = 'one_drive_session'

export const DROPBOX_REFRESH_TOKEN_COOKIE_NAME = 'one_drive_refresh_session'

export const INFLUENCER_COOKIE_NAME = 'is_influencer_shown'

export const IS_FIRST_TIME_INIT_AVATAR_COOKIE_NAME = 'is_first_time_init_avatar'

export const BINDING_THIRD_PARTY_COOKIE_NAME = 'is_binding_success'

export const PAL_STEP_COOKIE_NAME = 'pal_step'

export const PAL_MODE_COOKIE_NAME = 'pal_mode'

export const IS_EMBED_COOKIE_NAME = 'isEmbed'
export const TRACKER_COOKIE_NAME = 'tracker'

export const USER_INFO_TEMPLATE = '{guest}|{show_age_confirmation}|{user_id}'

export const BIRTHDAY_FORMAT = 'MM/DD/YYYY'

export const DEFAULT_ERROR_MESSAGE = 'Response Error'

export const OS_THRESHOLDS: Record<string, number> = {
  iOS: 16,
  Android: 8,
}

export const BROWSER = {
  Chrome: 'Chrome',
  'Mobile Chrome': 'Mobile Chrome',
  Safari: 'Safari',
  'Mobile Safari': 'Mobile Safari',
  Firefox: 'Firefox',
  'Mobile Firefox': 'Mobile Firefox',
}

export const BROWSER_THRESHOLDS: Record<string, number> = {
  [BROWSER.Chrome]: 109,
  [BROWSER['Mobile Chrome']]: 109,
  [BROWSER.Safari]: 16.6,
  [BROWSER['Mobile Safari']]: 16.6,
}

export const THIRD_PARTY_PLATFORMS = {
  TIKTOK: 'tiktok',
  INSTAGRAM: 'instagram',
  GOOGLE: 'google',
} as const

export const YOUTUBE_SCOPE = [
  'https://www.googleapis.com/auth/youtube.readonly',
]

export const GOOGLE_BASIC_SCOPE = ['email', 'profile']

export const GOOGLE_DRIVE_SCOPE = [
  'https://www.googleapis.com/auth/drive.readonly',
]

export const INSTAGRAM_BASIC_SCOPE = ['instagram_business_basic']

export const INSTAGRAM_CONTENT_PUBLISH_SCOPE = [
  'instagram_business_content_publish',
]

export const TIKTOK_BASIC_SCOPE = ['user.info.basic', 'user.info.stats']

export const TIKTOK_VIDEO_SCOPE = ['video.list']

export const DROPBOX_SCOPE = ['files.metadata.read', 'files.content.read']

export const ONE_DRIVE_SCOPE = ['onedrive.readonly']
